@import "../modules/typography";
.main {
  position: relative;
  width: 100%;
  height: 850px;
  overflow: hidden;
  background-image: linear-gradient(147deg, #021b79 0%, #0575e6 100%);
  @media only screen and (max-width:991px) {
    overflow: visible;
    height: auto;
  }
  &:before{
    content: "";
    position: absolute;
    width: 100%;
    height: 120px;
    background: #fff;
    bottom: -40px;
    display: block;
    transform: skewY(-2deg);
    z-index: 9;
    @media only screen and (max-width:991px) {
      height: 160px;
      background: #fff;
      bottom: -20px;
    }
  }

  &--bg {
    width: 100%;
    height: 100%;
    right: 0;
    position: absolute;
    top: 0;
    @media only screen and (max-width:991px) {
      display: none;
    }
    span {
      position: absolute;
      top: -180px;
      right: 148px;
      display: block;
      width: 392px;
      height: 1055px;
      transform: rotate(-30deg);
      background-image: linear-gradient(270deg,rgba(3,77,182,0),#044fb7);
      &:last-child{
        right: -17px;
        top: -170px;
      }
    }
  }
  .holder {
    display: flex;
  }
  &--content{
    position: relative;
    padding-top: 180px;
    z-index: 9;
    @media only screen and (max-width:991px) {
      z-index: 99;
      padding-top: 80px;
    }
    @media only screen and (max-width:770px) {
      padding-top: 110px;
    }
    h1 {
      margin-top: 0;
      color: #ffffff;
      font-family: $mainFont;
      font-weight: 900;
      font-size: 47px;
      line-height: 54px;
      max-width: 480px;
      @media only screen and (max-width:1200px) {
        font-size: 42px;
        line-height: 45px;
      }
      @media only screen and (max-width:770px) {
        text-align: center;
        font-size: 28px;
        font-weight: 900;
        line-height: 38px;
      }
    }
    p {
      max-width: 518px;
      color: #ffffff;
      font-family: $mainFont;
      font-size: 16px;
      font-weight: 400;
      line-height: 27px;
      @media only screen and (max-width:770px) {
        text-align: center;
      }
      span {
        font-weight: 700;
      }
    }
    &-buttons{
      margin-top: 32px;
      display: flex;
      align-items: center;
      @media only screen and (max-width:770px) {
        flex-direction: column;
      }
      span {
        display: inline-block;
        margin: 0 15px;
        color: #ffffff;
        font-family: $mainFont;
        font-size: 14px;
        font-weight: 500;
        line-height: 19px;
        @media only screen and (max-width:770px) {
          padding: 15px 0;
        }
      }
      .btn {
        flex-basis: 40%;
        height: 48px;
        border-radius: 3px;
        background-color: #499bff;
        color: #ffffff;
        font-family: $mainFont;
        font-size: 14px;
        font-weight: 700;
        line-height: 48px;

        transition: 0.3s all;
        padding: 0;
        &:last-child {
          background: transparent;
          border: 1px solid rgba(255, 255, 255, 0.5);
        }
        &:hover {
          transform: translateY(-1px);
        }
      }
    }
  }
  &--image {
    position: relative;
    padding-top: 160px;
    @media only screen and (max-width:1500px) {
     img {
       max-width: 100%;
     }
    }
    @media only screen and (max-width:991px) {
      z-index: 9;
    }
    @media only screen and (max-width:770px) {
      padding-top: 40px;
    }
    img {
      position: relative;
      @media only screen and (max-width:991px) {
        max-width: 100%;
      }
    }
  }
}

.features {
  margin-top: 10px;
  @media only screen and (max-width:991px) {
    margin-top: 50px;
  }
  &--items {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width:991px) {
      flex-direction: column;
    }
    &_item {
      position: relative;
      flex: 0 1 28%;
      @media only screen and (max-width:770px) {
        flex: 0 1 100%;
        text-align: center;
        margin-bottom: 20px;
        padding-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
      &:before {
        content: "";
        width: 1px;
        height: 100%;
        position: absolute;
        top: -15px;
        background: url(../img/line.png) no-repeat;
        right: -40px;

        @media only screen and (max-width:770px) {
          top: 0;
          background: url(../img/line.png) no-repeat;
          right: 0;
          left: 0;
          margin: auto;
          transform: rotate(90deg);
          content: none;
        }

      }
      &:last-child {
        &:before {
          content: none;
        }
      }
      p {
        color: #616161;
        font-family: $mainFont;
        font-size: 16px;
        font-weight: 400;
        line-height: 23px;
      }
    }
  }
}

.how-it-works {
  width: 100%;
  background-color: #f7fafe;
  padding: 90px 0;
  margin-top: 35px;
  position: relative;
  &:before{
    content: "";
    position: absolute;
    width: 100%;
    height: 85px;
    background: #fff;
    top: -40px;
    display: block;
    transform: skewY(-2deg);
    z-index: 1;
  }
  &__content {
    padding-top: 65px;
    @media only screen and (max-width:991px) {
      padding-top: 30px;
    }
    @media only screen and (max-width:770px) {
      text-align: center;
      margin-bottom: 40px;
    }
  }
  &__image {
    padding-top: 15px;
    img {
      max-width: 100%;
    }
  }
}

.stats {
  width: 100%;
  min-height: 260px;
  position: relative;
  background-image: linear-gradient(156deg, #021b79 0%, #0575e6 100%);
  &:before{
    content: "";
    background: url("../img/line_01.svg") no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  &_items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 260px;
    @media only screen and (max-width:991px) {
      flex-direction: column;
      padding-top: 30px;
      padding-bottom: 30px;
    }
    &__item {
      flex: 0 1 35%;
      border-right: 1px solid rgba(255, 255, 255, 0.25);
      position: relative;
      @media only screen and (max-width:991px) {
        border-right: 0;
        margin-bottom: 15px;
        padding-bottom: 15px;
        flex: 0 1 100%;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          width: 72px;
          height: 2px;
          background-color: #ffffff;
          opacity: 0.2;
          top: -10px;
        }
        &:first-child {
          &:before {
            content: none;
          }
        }
        &:last-child{
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
      &:last-child{
        border-right: 0;
      }
      h1 {
        margin-top: 0;
        color: #ffffff;
        font-family: $mainFont;
        font-size: 48px;
        font-weight: 600;
        line-height: 63px;
        text-align: center;
        letter-spacing: 1.5px;
        margin-bottom: 10px;
      }
      p {
        color: #ffffff;
        font-family: $mainFont;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 0.5px;
      }
    }
  }
}

.try {
  margin-top: 125px;
  @media only screen and (max-width:991px) {
    margin-top: 45px;
  }
  &_content {
    @media only screen and (max-width:991px) {
      margin-bottom: 35px;
    }
    @media only screen and (max-width:770px) {
      text-align: center;
    }
  }
  pre {
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    background-color: #ffffff;
  }
  .line-numbers .line-numbers-rows {
    top: -18px;
    background-color: #f5fef2;
    padding-top: 16px;
    padding-bottom: 16px;
    border-right: 0;
  }
  .line-numbers-rows > span:before {
    color: #9ad886;
  }
  ul#pills-tab {
    @media only screen and (max-width:770px) {
      display: flex;
    }
  }
  .nav-item {
    margin-right: 7px;
    margin-bottom: 7px;
    &:last-child {
      margin-right: 0;
    }
    @media only screen and (max-width:770px) {
      flex: 0 1 47%;
      margin-bottom: 10px;

    }
    a {
      min-width: 95px;
      height: 40px;
      border-radius: 3px;
      text-align: left;
      background-color: #f6f9fc;
      color: #a5b1be;
      font-family: $mainFont;
      font-size: 14px;
      font-weight: 600;
      @media only screen and (max-width:1200px) {
        min-width: 100px;
      }
      &.active {
        box-shadow: 0 3px 6px rgba(44, 44, 44, 0.1);
        background-color: #ffffff;
        color: #499bff;
      }
    }
  }
}

.subscribe {
  margin-top: 120px;
  @media only screen and (max-width:991px) {
    margin-top: 80px;
  }
  &_items{
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width:991px) {
      flex-direction: column;
    }
    &__item{
      position: relative;
      overflow: hidden;
      flex: 0 1 48%;
      min-height: 225px;
      box-shadow: 0 2px 16px rgba(44, 44, 44, 0.12);
      transition: 0.6s all;
      border-radius: 3px;
      background-color: #ffffff;
      padding: 32px 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:hover {
        box-shadow: 0 2px 16px rgba(44, 44, 44, 0.25);
      }
      @media only screen and (max-width:991px) {
        flex: 0 1 100%;
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &:before {
        content: "";
        position: absolute;
        width: 242px;
        height: 130%;
        background-color: rgba(73,155,255,.08);
        left: -150px;
        border-radius: 8rem / 10rem;
        @media only screen and (max-width:770px) {
          left: -160px;
        }
      }
      a {
        color: #499bff;
        font-family: $mainFont;
        font-size: 14px;
        font-weight: 700;
        line-height: 19px;
        span {
          margin-left: 10px;
        }
        &:hover{
          text-decoration: none;
        }
      }
      div {
        &:first-child {
          flex: 0 1 65%;
          @media only screen and (max-width:1200px) {
            flex: 0 1 85%;
          }
          @media only screen and (max-width:991px) {
            flex: 0 1 35%;
          }
          @media only screen and (max-width:770px) {
            flex: 0 1 75%;
          }
        }
        &:last-child {
          flex: 0 1 245%;
        }
      }
      &:last-child {
        &:before {
          background-color: rgba(220, 95, 92, 0.08);
        }
        a {
          color: #dc5f5c;
        }
      }
    }
  }
}

.contact-us {
  margin-top: 105px;
  margin-bottom: 70px;
  &_items {
    display: flex;
    align-items: center;
    @media only screen and (max-width:770px) {
      flex-direction: column;
    }
    .content {
      flex: 0 1 40%;
      @media only screen and (max-width:991px) {
        flex: 0 1 80%;
      }
      @media only screen and (max-width:770px) {
        text-align: center;
      }
      a {
        color: #67a7f5;
      }
    }
    .buttons {
      margin-left: auto;
      @media only screen and (max-width:991px) {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
      }
      @media only screen and (max-width:770px) {
        justify-content: center;
        margin-left: initial;
      }
      a {
        border: 1px solid transparent;
        &:last-child {
          margin-left: 10px;
          background: #FFF;
          border: 1px solid #499bff;
          color: #499bff;
          @media only screen and (max-width:991px) {
            margin-left: 0;
          }
        }
      }
    }
  }
}
