@import "typography";
button.primary, a.primary {
  flex-basis: 40%;
  height: 48px;
  border-radius: 3px;
  background-color: #499bff;
  color: #ffffff;
  font-family: $mainFont;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  transition: 0.3s all;
  border: 0;
  min-width: 218px;
  cursor: pointer;
  &:hover {
    transform: translateY(-1px);
    text-decoration: none;
  }
}

a.primary {
  display: inline-block;
  line-height: 48px;
  text-align: center;
}
