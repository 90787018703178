@import "../modules/typography";
.footer {
  border-top: 1px solid #e8eaec;
  padding: 25px 0 55px;
  &_items {
    display: flex;
    @media only screen and (max-width:770px) {
      flex-direction: column;
    }
    .copyright {
      color: #868686;
      font-family: $mainFont;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.4px;
    }
    nav {
      margin-left: auto;
      @media only screen and (max-width:770px) {
        margin-left: initial;
        margin-top: 15px;
      }
      ul {
        display: flex;
        margin-bottom: 0;
        li {
          list-style: none;
          margin-right: 10px;
          padding-right: 10px;
          border-right: 1px solid #cdcdcd;
          &:last-child {
            margin-right: 0;
            border-right: 0;
          }
          a {
            color: #868686;
            font-family:  $mainFont;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.44px;
          }
        }
      }
    }
  }
}
