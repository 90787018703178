@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');

$mainFont: 'Lato', sans-serif;

h2 {
  color: #2c2c2c;
  font-family: $mainFont;
  font-size: 32px;
  font-weight: 800;
  line-height: 44px;
  letter-spacing: 1px;
  margin-bottom: 0;
}

h3 {
  color: #2c2c2c;
  font-family: $mainFont;
  font-size: 18px;
  font-weight: 800;
  line-height: 29px;
}

p {
  color: #616161;
  font-family: $mainFont;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

ul, ol {
  li {
    color: #616161;
    font-family: $mainFont;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}
