@import "../modules/typography";
.navigation {
  position: absolute;
  width: 100%;
  padding-top: 23px;
  z-index: 999;
  transition: 0.6s all;
  .logo {
    position: relative;
    padding-top: 5px;
    z-index: 999 !important;
    .blue {
      display: none;
    }
    .normal {
      display: block;
    }
  }
  .holder {
    display: flex;
    position: relative;
    .nav-right {
      margin-left: auto;
    }
  }
  .main-nav {
    display: flex;
    justify-content: flex-end;
    @media only screen and (max-width:991px) {
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      z-index: 99;
      top: 0;
      background-image: linear-gradient(147deg,#021b79,#0575e6);
      justify-content: flex-start;
      flex-direction: column;
      padding: 80px 25px;
      transform: translateX(-100%);
      transition: 0.6s all;
      &.open {
        transform: translateX(0);
      }
      @media only screen and (max-width:770px) {
        padding: 80px 25px;
      }
    }
    ul {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 0;
      padding-bottom: 0;
      padding-left: 0;
      @media only screen and (max-width:991px) {
        justify-content: flex-start;
        flex-direction: column;
      }
      li {
        list-style: none;
        margin-right: 32px;
        a {
          color: #ffffff;
          font-family: $mainFont;
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          text-transform: uppercase;
          transition: 0.6s all;
          padding: 10px 0;
          display: block;
          &:hover {
            text-decoration: none;
            color: rgba(255, 255, 255, 0.8);
          }
        }
        &:last-child{
          margin-right: 0;
        }
      }
      &:last-child{
        display: flex;
        margin-left: 28px;
        position: relative;
        @media only screen and (max-width:991px) {
          margin-left: 0;
        }
        &:before{
          content: '';
          position: absolute;
          width: 1px;
          height: 24px;
          background-color: #ffffff;
          opacity: 0.16;
          left: -17px;
          top: 10px;
          @media only screen and (max-width:991px) {
            content: none;
          }
        }
        li {
          list-style: none;
          margin-right: 32px;
          a {
            display: block;
            color: #ffffff;
            font-family: $mainFont;
            font-size: 14px;
            font-weight: 700;
            line-height: 19px;
            padding: 10px 0;
            transition: 0.6s all;
            text-transform: none;
            &:hover {
              text-decoration: none;
              color: rgba(255, 255, 255, 0.8);
            }
          }
          &:last-child{
            margin-right: 0;
            a {
              display: block;
              width: 90px;
              height: 39px;
              border-radius: 3px;
              border: 1px solid rgba(255, 255, 255, 0.25);
              padding: 10px;
              text-align: center;
              &:hover {
                border: 1px solid rgba(255, 255, 255, 1);
              }
            }
          }
        }
      }
    }
  }
  &.sticky {
    width: 100%;
    position: fixed;
    background: #FFF;
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,.08);
    top: 0;
    .logo {
      .blue {
        display: block;
      }
      .normal {
        display: none;
      }
    }
    .main-nav {
      @media only screen and (max-width:991px) {
        background-image: none;
        background: #FFF;
      }
      ul {
        li {
          a {
            color: #1b62b7;
            &:hover {
              text-decoration: none;
              color: #1b62b7;
            }
          }
          &:last-child{
            margin-right: 0;
          }
        }
        &:last-child{
          &:before{
            background-color: #1b62b7;
          }
          li {
            a {
              color: #1b62b7;
              &:hover {
                color: #1b62b7;
              }
            }
            &:last-child{
              a {
                border: 1px solid #1b62b7;
                &:hover {
                  border: 1px solid #1b62b7;
                }
              }
            }
          }
        }
      }
    }
  }
}

.mobile-nav {
  position: absolute;
  cursor: pointer;
  display: inline-block;
  width: 50px;
  height: 50px;
  z-index: 999;
  vertical-align: middle;
  transition: 0.6s all;
  margin-left: auto;
  text-align: right;
  float: right;
  top: 15px;
  right: 0;
    span {
      transition: 0.6s all;
      position: absolute;
      width: 100%;
      height: 3px;
      background: #FFF;
      display: block;
      &:first-child {
        top: -10px;
      }
      &:last-child {
        top: 10px;
      }
    }
  &.open {
    transition: 0.6s all;
    span {
      background: transparent;
      &:first-child {
        top: 0;
        background: #FFF;
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      &:last-child {
        top: 0;
        background: #FFF;
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }
  }
}


.sticky {
  .mobile-nav {
    span {
      background: #1b62b7;
    }
    &.open {
      span {
        background: transparent;
        &:first-child {
          background: #1b62b7;
        }
        &:last-child {
          background: #1b62b7;
        }
      }
    }
  }
}




