@import "typography";
* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}


.alert-success {
  z-index: 9;
  font-family: $mainFont;
}

.alert-dismissible .close {
  top: -13px;
}
.alert {
  padding: .75rem 3.25rem;
  border-radius: 0;
  @media only screen and (max-width:991px) {
    padding: 10px;
  }
}
